<template>
  <div class="container">
    <div class="linear"></div>
    <van-collapse class="collapse" v-model="activeName">
      <van-collapse-item class="collapse_item name" :title="list.name" name="1">
        <van-cell title="应聘岗位" :value="list.postname"/>
        <van-cell title="手机号码" :value="list.phone"/>
        <van-cell title="学历" :value="list.xueli"/>
        <van-cell title="性别" :value="list.gender"/>
        <van-cell title="年龄" :value="list.age"/>
        <van-cell title="出生日期" :value="list.birthday"/>
        <van-cell title="身高" :value="list.height + '(cm)'"/>
        <van-cell title="体重" :value="list.weight + '(Kg)'"/>
        <van-cell title="民族" :value="list.minzu"/>
        <van-cell title="婚姻状况" :value="list.merriage"/>
        <van-cell title="身份证" :value="list.identity"/>
        <van-cell title="身份证住址" :value="list.iden_address"/>
        <van-cell title="现住址" :value="list.address"/>
        <van-cell title="期望薪资" :value="list.salary"/>
        <van-cell title="最低期望薪资" :value="list.accept_salary"/>
      </van-collapse-item>
      <div class="linear"></div>
      <van-collapse-item class="collapse_item" title="教育经历" name="2">
        <div v-for="(item,index) in list.edu_list" :key="index">
          <van-cell title="学习时段" :value="item.during"/>
          <van-cell title="学校名称" :value="item.schoolName"/>
          <van-cell title="学历" :value="item.xueli"/>
          <van-cell title="专业" :value="item.professional"/>
        </div>
      </van-collapse-item>
      <div class="linear"></div>
      <van-collapse-item class="collapse_item" title="工作经历" name="3">
        <div v-for="(item,index) in list.work_list" :key="index">
          <van-divider content-position="left"
                       :style="{ color: '#02DBCA', borderColor: '#02DBCA', padding: '0 16px',margin:'7px 0' }">
            工作经历{{ index + 1 }}
          </van-divider>
          <van-cell title="工作时段" :value="item.during"/>
          <van-cell title="工作职务" :value="item.post"/>
          <van-cell title="工作单位" :value="item.workplace"/>
          <van-cell title="上级" :value="item.phoneandname"/>
        </div>
      </van-collapse-item>
      <div class="linear"></div>
      <van-collapse-item title="面试考核维度" name="4">
        <van-form class="form" @submit="onSubmit">
          <van-cell title="单元格">
            <template #title>
              <div class="title">
                <span class="num">1</span><span class="titles">过往经验和能力</span>
              </div>
            </template>
          </van-cell>
          <van-field
              v-model="params.area1"
              name="area1"
              label=""
              type="textarea"
              autosize
              maxlength="100"
              show-word-limit
              placeholder="请点评该应聘者过往经验和能力"
              :rules="[{ required: true, message: '' }]"
          />
          <van-cell title="单元格">
            <template #title>
              <div class="title">
                <span class="num">2</span><span class="titles">学习和成长能力</span>
              </div>
            </template>
          </van-cell>
          <van-field
              v-model="params.area2"
              name="area2"
              label=""
              type="textarea"
              autosize
              maxlength="100"
              show-word-limit
              placeholder="请点评该应聘者学习和成长能力"
              :rules="[{ required: true, message: '' }]"
          />
          <van-cell title="单元格">
            <template #title>
              <div class="title">
                <span class="num">3</span><span class="titles">价值观契合度</span>
              </div>
            </template>
          </van-cell>
          <van-field
              v-model="params.area3"
              name="area3"
              label=""
              type="textarea"
              autosize
              maxlength="100"
              show-word-limit
              placeholder="请点评该应聘者价值观契合度"
              :rules="[{ required: true, message: '' }]"
          />
          <div class="select">
            <div block class="no" @click="saves">面试不通过</div>
            <van-button block class="yes" @click="params.isPass = 1">面试通过</van-button>
          </div>
        </van-form>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { GetApprovalDetail,SubmitResults } from "@/api/examiner";

export default {

  data() {
    return {
      activeName: ['1', '4'],
      val: '',
      list: [],
      params: {
        peo_id: -1,
        isPass: -1,
        area1: "",
        area2: "",
        area3: "",
        openId: this.$store.state.info.openId
      }
    }
  },
  created() {
    this.$store.dispatch('changeLocation', '应聘者信息')
    if (!this.$route.query.id) {
      this.$toast('出现错误，请重试')
      return
    }
    this.params.peo_id = this.$route.query.id;
    this.fetchData()
  },
  methods: {
    fetchData() {
      let params = {
        peoId: this.params.peo_id,
        openId: this.$store.state.info.openId
      }
      GetApprovalDetail(params).then(res => {
        console.log(res);
        if (res.errcode !== 200) {
          this.$toast(res.message)
          return
        }
        this.list = res.row;
      })
    },
    saves(){
      this.params.isPass = 0;
      console.log(this.params);
      SubmitResults(this.params).then(res=> {
        console.log(res);
        if(res.errcode != 200){
          this.$toast(res.message)
          return
        }
        this.$toast('审核成功')
        setTimeout(()=> {
          this.$router.back()
        },1000)
      })
    },
    onSubmit(values) {
      console.log(this.params);
      SubmitResults(this.params).then(res=> {
        console.log(res);
        if(res.errcode != 200){
          this.$toast(res.message)
          return
        }
        this.$toast('审核成功')
        setTimeout(()=> {
          this.$router.back()
        },1000)
      })
    },
  }
}
</script>

<style scoped lang="scss">
p{
  margin: 0;
}
.container {
  .linear {
    width: 100%;
    height: 10px;
    background: #F7F7F7;
  }

  .collapse {
    ::v-deep .van-collapse-item__content {
      padding-top: 3px;
      padding-bottom: 5px;
    }

    ::v-deep .van-collapse-item__title .van-cell__title {
      font-size: 17px;
      font-weight: 700;
    }

    .name {
      ::v-deep .van-cell__value {
        flex: 3;
      }

    }

    .collapse_item {
      .van-cell {
        padding: 5px 0;

        &::after {
          display: none;
        }
      }

      .cell_item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: normal;
          color: #999999;
          line-height: 25px;
        }

        .val {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: normal;
          color: #333333;
          line-height: 26px;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .num {
      width: 15px;
      height: 15px;
      background: #02DBCA;
      border-radius: 50%;
      text-align: center;
      line-height: 15px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
    }

    .titles {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
    }
  }

  .form {
    ::v-deep .van-cell {
      padding-left: 0;
    }

    .select {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;

      .van-button {
        width: 160px;
        height: 46px;
        border-radius: 4px;
        text-align: center;
        box-sizing: border-box;
        line-height: 45px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
      }

      .no {
        color: #02DBCA;
        background-color: #fff;
        border: 1px solid #02DBCA;
        margin-right: 15px;
            width: 4.26667rem;
         height: 1.18rem;
         text-align: center;
         line-height: 1.22667rem;
         border-radius: 3px;
      }

      .yes {
        color: #FFFFFF;
        background: #02DBCA;
      }
    }
  }
}
</style>
